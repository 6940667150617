import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
import { getToken } from '@/utils/auth';

Vue.use(VueRouter);
// 解决路由重复报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/userView'),
      },
      {
        path: '/us',
        name: 'us',
        component: () => import('@/views/usView'),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 前置导航守卫
const whiteList = ['/login'];
router.beforeEach((to, from, next) => {
  // 如果没有token并且去的不是白名单页面就强制拦截到登录页
  const phone = getToken();
  if (!phone && !whiteList.includes(to.path)) return next('/login');
  // return false 或者不写 表示放行
  next();
});

export default router;
