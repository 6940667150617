<template>
  <div class="container">
    <el-container>
      <el-aside width="300px"><NavMenu></NavMenu></el-aside>
      <div class="zhankeng" style="display: inline-block;width: 300px;"></div>
      <el-container>
        <el-header>
          <NavBar :username="username"></NavBar>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavMenu from './components/NavMenu.vue';
import NavBar from './components/NavBar2.vue';
export default {
  name: 'Layout-View',
  components: { NavMenu, NavBar },
  data() {
    return {
      username: '',
    };
  },
  created() {
  },
};
</script>

<style scoped>
.row-left {
  height: 100vh;
  width: 300px;
  background-color: #545c64;
  overflow: hidden;
}
.el-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px !important;
  height: 100vh;
  overflow-x: hidden;
  background-color: #545c64;
}
.el-header {
  padding: 0;
}
</style>
