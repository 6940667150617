<template>
  <div class="container">
    <div class="logo">名片管理</div>
    <el-menu
      :default-active="active"
      class="el-menu-vertical-demo"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#aca5f2"
    >
      <el-menu-item index="/home">
        <i class="el-icon-s-home"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/us">
        <i class="el-icon-chat-line-square"></i>
        <span slot="title">公司管理</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  data() {
    return {
      active: this.$route,
    };
  },
  created() {
    this.defaultNav();
  },
  methods: {
    handleSelect(key) {
      this.$router.push({ path: key });
      // console.log(key, keyPath);
    },
    // 设置默认选中的导航
    defaultNav() {
      // console.log(this.$route.path);
      this.active = this.$route.path;
    },
  },
};
</script>

<style scoped>
.logo {
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 18px;
}
.logo img {
  width: 40px;
  vertical-align: middle;
}
.row-left {
  height: 100vh;
  width: 300px;
  background-color: #545c64;
  overflow: hidden;
}
.el-aside {
  height: 100%;
  overflow-x: hidden;
}
.el-menu {
  border-right: 0px;
}
.el-menu-item {
  font-size: 16px;
}
.el-submenu__title span {
  font-size: 16px;
}
</style>
