<template>
  <div class="navbar">
    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img src="" class="user-avatar" /> -->
          <!-- <span v-else class="text-avatar">1</span> -->
          <span class="name">{{ this.username }}</span>
          <i class="el-icon-setting" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <!-- <router-link to="/">
            <el-dropdown-item> 首页 </el-dropdown-item>
          </router-link> -->
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    username: {
      type: String,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 退出
    logout() {
      // 1.询问是否确认退出
      this.$confirm('此操作将永久退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 2.清除token
          this.$store.commit('logout');
          // 3.跳转到登录
          this.$router.push('/login');
          this.$message.success('退出成功');
        })
        .catch(() => {
          this.$message.info('已取消');
        });
    },
  },
};
</script>

<style scoped>
.text-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background: #04c9be;
  color: #fff;
  margin-right: 4px;
}
.name {
  font-size: 18;
  margin-right: 10px;
}
.el-icon-setting {
  font-size: 20px;
}
.navbar {
  height: 50px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.navbar .hamburger-container {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
}

.navbar .hamburger-container:hover {
  background: rgba(0, 0, 0, 0.025);
}

.navbar .breadcrumb-container {
  float: left;
}

.navbar .right-menu {
  float: right;
  height: 100%;
  line-height: 50px;
}

.navbar .right-menu:focus {
  outline: none;
}

.navbar .right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;
}

.navbar .right-menu-item .hover-effect {
  cursor: pointer;
  transition: background 0.3s;
}

.navbar .right-menu-item .hover-effect:hover {
  background: rgba(0, 0, 0, 0.025);
}

.avatar-container {
  margin-right: 30px;
}

.avatar-wrapper {
  margin-top: 5px;
  position: relative;
}

.user-avatar {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
}

.el-icon-caret-bottom {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 25px;
  font-size: 12px;
}
</style>
