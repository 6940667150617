import Vue from 'vue'
import Vuex from 'vuex'
import {getToken, removeToken, setToken} from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken || ''
  },
  getters: {
  },
  mutations: {
    // 设置token
    setToken(state,payload){
      state.token=payload //存储token到vuex
      setToken(payload) //存储token到本地
    },
    // 清除token
    logout(state){
      state.token=''
      removeToken()
    },
  },
  actions: {
  },
  modules: {
  }
})
