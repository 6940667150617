import Vue from 'vue';
import ElementUI from 'element-ui';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './assets/css/element-variables.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/icons'; // icon
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Element, { size: 'small', zIndex: 3000 });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
